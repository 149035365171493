<template>
    <div>
        <h1>Three Card Reading</h1>
        <div v-if="cards.length" class="cards-container">
            <div v-for="card in cards" :key="card.name" class="card">
                <img :src="getCardImage(card.img)" :alt="card.name" />
                <h2>{{ card.name }}</h2>
                <p>{{ card.Affirmation }}</p>
                <p><strong>Astrology:</strong> {{ card.Astrology }}</p>
                <p><strong>Numerology:</strong> {{ card.Numerology }}</p>
                <p><strong>Keywords:</strong> {{ card.keywords.join(', ') }}</p>
                <div>
                    <h3>Meanings</h3>
                    <p><strong>Light:</strong> {{ card.meanings.light.join(', ') }}</p>
                    <p><strong>Shadow:</strong> {{ card.meanings.shadow.join(', ') }}</p>
                </div>
            </div>
        </div>
        <div v-else>
            <p>Loading cards...</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            cards: []
        };
    },
    methods: {
        getCardImage(imageName) {
            return `https://nantarat.xyz/api/card_image/${imageName}`;
        }
    },
    mounted() {
        axios.get('https://nantarat.xyz/api/reading/three')
            .then(response => {
                this.cards = response.data;
            })
            .catch(error => {
                console.error('Error fetching cards:', error);
            });
    }
};
</script>

<style scoped>
.cards-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.card {
    margin: 10px;
    text-align: center;
    width: 30%;
}
.card img {
    width: 100px;
    height: 150px;
}
</style>