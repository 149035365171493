<template>
    <div class="tarot-spread">
      <div v-if="!isSpread" class="deck" @click="spreadCards">
        <div class="card back">Click to Spread</div>
      </div>
      <div v-else class="spread">
        <div 
          v-for="(card, index) in cards" 
          :key="index" 
          class="card" 
          :class="{ 'hovered': hoveredCard === index }"
          :style="getCardPosition(index)"
          @click="selectCard(index)"
          @mouseover="hoverCard(index)"
          @mouseleave="unhoverCard()"
        >
          <img :src="require('@/assets/tarot_back.jpg')" alt="Card Back" class="card-back" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        isSpread: false,
        cards: [],
        hoveredCard: null
      }
    },
    methods: {
      async spreadCards() {
        this.isSpread = true;
        await this.fetchCards();
        this.shuffleCards();
      },
      async fetchCards() {
        try {
          const response = await axios.get('https://nantarat.xyz/api/cards');
          this.cards = response.data;
        } catch (error) {
          console.error('Error fetching cards:', error);
        }
      },
      shuffleCards() {
        for (let i = this.cards.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [this.cards[i], this.cards[j]] = [this.cards[j], this.cards[i]];
        }
      },
      getCardPosition(index) {
        const totalCards = this.cards.length;
        const angle = (index / totalCards) * 2 * Math.PI;
        const radius = 300; // Adjust as needed
        const x = Math.cos(angle) * radius;
        const y = Math.sin(angle) * radius;
        return {
          transform: `translate(${x}px, ${y}px) rotate(${angle * 180 / Math.PI}deg)`,
        }
      },
      selectCard(index) {
        const card = this.cards[index];
        alert(`You selected ${card.name} (${card.arcana})`);
      },
      hoverCard(index) {
        this.hoveredCard = index;
      },
      unhoverCard() {
        this.hoveredCard = null;
      }
    }
  }
  </script>
  
  <style scoped>
  .tarot-spread {
    width: 700px;
    height: 700px;
    position: relative;
    margin: 0 auto;
  }
  
  .deck, .spread {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card {
    width: 60px;
    height: 100px;
    position: absolute;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .card.back {
    background-image: url('~@/assets/tarot_back.jpg');
    background-size: cover;
    width: 100px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    text-shadow: 1px 1px 2px black;
  }
  
  .spread .card {
    transform-origin: center 350px;
  }
  
  .spread .card.hovered {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(255,255,255,0.8);
    z-index: 100;
  }
  </style>