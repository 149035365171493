<template>
    <div class="card-slider" @wheel="handleWheel">
      <h1>Tarot Card Slider</h1>
      <div 
        class="slider-container" 
        ref="sliderContainer"
        @touchstart="touchStart"
        @touchmove="touchMove"
        @touchend="touchEnd"
      >
        <div 
          v-for="(card, index) in cards" 
          :key="card.name" 
          class="card-slide"
          :class="{ 'active': index === currentIndex }"
          :style="getCardStyle(index)"
        >
          <img :src="getCardImage(card.img)" :alt="card.name" />
          <h2>{{ card.name }}</h2>
        </div>
      </div>
      <div class="navigation">
        <button @click="prevCard">Previous</button>
        <button @click="nextCard">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        cards: [],
        currentIndex: 0,
        touchStartX: 0,
        touchEndX: 0,
      };
    },
    methods: {
      getCardImage(imageName) {
        return `https://nantarat.xyz/api/card_image/${imageName}`;
      },
      async fetchCards() {
        try {
          const response = await axios.get('https://nantarat.xyz/api/cards');
          if (Array.isArray(response.data.cards)) {
            this.cards = response.data.cards;
          } else if (Array.isArray(response.data)) {
            this.cards = response.data;
          } else {
            throw new Error('Unexpected API response structure');
          }
        } catch (error) {
          console.error('Error fetching cards:', error);
        }
      },
      getCardStyle(index) {
        const diff = index - this.currentIndex;
        const translateX = diff * 100;
        const scale = index === this.currentIndex ? 1.2 : 0.8;
        const zIndex = index === this.currentIndex ? 10 : 1;
        return {
          transform: `translateX(${translateX}%) scale(${scale})`,
          zIndex: zIndex,
        };
      },
      nextCard() {
        if (this.currentIndex < this.cards.length - 1) {
          this.currentIndex++;
        }
      },
      prevCard() {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        }
      },
      touchStart(event) {
        this.touchStartX = event.touches[0].clientX;
      },
      touchMove(event) {
        this.touchEndX = event.touches[0].clientX;
      },
      touchEnd() {
        if (this.touchStartX - this.touchEndX > 50) {
          this.nextCard();
        } else if (this.touchEndX - this.touchStartX > 50) {
          this.prevCard();
        }
      },
      handleWheel(event) {
        if (event.deltaY > 0) {
          this.nextCard();
        } else {
          this.prevCard();
        }
      },
    },
    mounted() {
      this.fetchCards();
    }
  };
  </script>
  
 
  <style scoped>
.card-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
  height: 700px; /* Increased height */
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  touch-action: pan-y;
}

.card-slide {
  position: absolute;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  max-width: 300px;
  height: 100%;
}

.card-slide.active {
  z-index: 10;
}

.card-slide img {
  max-height: 60%;
  width: auto;
  object-fit: contain;
}

h2 {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.navigation {
  position: absolute;
  bottom: 40px; /* Moved further down */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.navigation button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 20;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .card-slider {
    height: 600px; /* Adjusted height for mobile */
  }

  .card-slide img {
    max-height: 50%; /* Smaller images on mobile */
  }

  h2 {
    font-size: 1rem;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .navigation {
    bottom: 20px; /* Adjusted for mobile */
  }

  .navigation button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
</style>