<template>
    <button @click="toggleTheme" class="theme-toggle">
      {{ isDarkMode ? '🌞' : '🌙' }}
    </button>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isDarkMode: false
      }
    },
    methods: {
      toggleTheme() {
        this.isDarkMode = !this.isDarkMode;
        document.body.setAttribute('data-theme', this.isDarkMode ? 'dark' : 'light');
      }
    },
    mounted() {
      // Check for saved theme preference or system preference
      const savedTheme = localStorage.getItem('theme');
      const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      
      if (savedTheme === 'dark' || (!savedTheme && prefersDark)) {
        this.isDarkMode = true;
        document.body.setAttribute('data-theme', 'dark');
      }
    },
    watch: {
      isDarkMode(newValue) {
        localStorage.setItem('theme', newValue ? 'dark' : 'light');
      }
    }
  }
  </script>
  
  <style scoped>
  .theme-toggle {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  
  .theme-toggle:hover {
    background-color: var(--secondary-color);
  }
  </style>