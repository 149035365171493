<template>
    <div class="reading-container">
      <h2>AI Interpreted Three Card Reading</h2>
      <button @click="performReading" :disabled="isLoading">Perform Reading</button>
      <div v-if="isLoading" class="loading">Loading...</div>
      <div v-if="error" class="error">{{ error }}</div>
      <div v-if="cards.length && interpretation" class="reading-result">
        <div class="cards-container">
          <div v-for="(card, index) in cards" :key="index" class="card">
            <h3>{{ card.name }}</h3>
            <img :src="getCardImage(card.img)" :alt="card.name" />
            <p><strong>Keywords:</strong> {{ card.keywords.join(', ') }}</p>
          </div>
        </div>
        <div class="interpretation">
          <h3>Interpretation</h3>
          <div v-html="formattedInterpretation"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        isLoading: false,
        error: null,
        cards: [],
        interpretation: ''
      };
    },
    computed: {
      formattedInterpretation() {
        if (!this.interpretation) return '';
        
        // Split the interpretation into sections
        const sections = this.interpretation.split(/\d+\.\s+/);
        
        // Format each section
        return sections.map(section => {
          if (section.trim() === '') return ''; // Skip empty sections
          const [title, ...content] = section.split(':');
          if (content.length > 0) {
            return `<h4>${title.trim()}:</h4><p>${content.join(':').trim()}</p>`;
          } else {
            return `<p>${section.trim()}</p>`;
          }
        }).join('');
      }
    },
    methods: {
      async performReading() {
        this.isLoading = true;
        this.error = null;
        this.cards = [];
        this.interpretation = '';
  
        try {
          const response = await axios.get('https://nantarat.xyz/api/reading/three_with_interpretation');
          this.cards = response.data.cards;
          this.interpretation = response.data.interpretation;
        } catch (error) {
          this.error = "Error performing AI reading. Please try again later.";
          console.error('Error:', error);
        } finally {
          this.isLoading = false;
        }
      },
      getCardImage(imageName) {
        return `https://nantarat.xyz/api/card_image/${imageName}`;
      }
    }
  };
  </script>
  
  <style scoped>
  /* ... (keep existing styles) ... */
  
  .interpretation h4 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .interpretation p {
    margin-bottom: 15px;
  }
  </style>