<template>
  <div class="card-gallery">
    <h1>Tarot CARDZ</h1>
    <p>Debug: {{ debugInfo }}</p>
    <div v-if="categorizedCards && Object.keys(categorizedCards).length > 0">
      <!-- Render Major Arcana first -->
      <div v-if="categorizedCards['Major Arcana'] && categorizedCards['Major Arcana'].length > 0">
        <h2>Major Arcana ({{ categorizedCards['Major Arcana'].length }} cards)</h2>
        <div class="card-grid">
          <div v-for="card in categorizedCards['Major Arcana']" :key="card.name" class="card" @click="selectCard(card)">
            <img :src="getCardImage(card.img)" :alt="card.name" />
            <p>{{ card.name }}</p>
          </div>
        </div>
      </div>
      <!-- Then render other suits -->
      <div v-for="categoryName in ['Wands', 'Cups', 'Swords', 'Pentacles']" :key="categoryName">
        <div v-if="categorizedCards[categoryName] && categorizedCards[categoryName].length > 0">
          <h2>{{ categoryName }} ({{ categorizedCards[categoryName].length }} cards)</h2>
          <div class="card-grid">
            <div v-for="card in categorizedCards[categoryName]" :key="card.name" class="card" @click="selectCard(card)">
              <img :src="getCardImage(card.img)" :alt="card.name" />
              <p>{{ card.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>{{ cards.length > 0 ? 'Categorizing cards...' : 'No cards available. Please try again later.' }}</p>
    </div>

    <!-- Modal -->
    <div v-if="selectedCard" class="modal" @click="closeModal">
      <div class="modal-content" @click.stop>
        <h2>{{ selectedCard.name }}</h2>
        <img :src="getCardImage(selectedCard.img)" :alt="selectedCard.name" />
        <p>Arcana: {{ selectedCard.arcana }}</p>
        <p>Number: {{ selectedCard.number }}</p>
        <p v-if="selectedCard.suit">Suit: {{ selectedCard.suit }}</p>
        <p>Fortune Telling:</p>
        <ul>
          <li v-for="(fortune, index) in selectedCard.fortune_telling" :key="index">{{ fortune }}</li>
        </ul>
        <p>Keywords:</p>
        <ul>
          <li v-for="(keyword, index) in selectedCard.keywords" :key="index">{{ keyword }}</li>
        </ul>
        <p>Meanings:</p>
        <div v-if="selectedCard.meanings">
          <h3>Light:</h3>
          <ul>
            <li v-for="(meaning, index) in selectedCard.meanings.light" :key="index">{{ meaning }}</li>
          </ul>
          <h3>Shadow:</h3>
          <ul>
            <li v-for="(meaning, index) in selectedCard.meanings.shadow" :key="index">{{ meaning }}</li>
          </ul>
        </div>
        <p v-if="selectedCard.Archetype">Archetype: {{ selectedCard.Archetype }}</p>
        <p v-if="selectedCard['Hebrew Alphabet']">Hebrew Alphabet: {{ selectedCard['Hebrew Alphabet'] }}</p>
        <p v-if="selectedCard.Numerology">Numerology: {{ selectedCard.Numerology }}</p>
        <p v-if="selectedCard.Elemental">Elemental: {{ selectedCard.Elemental }}</p>
        <p v-if="selectedCard['Mythical/Spiritual']">Mythical/Spiritual: {{ selectedCard['Mythical/Spiritual'] }}</p>
        <p v-if="selectedCard['Questions to Ask']">Questions to Ask:</p>
        <ul v-if="selectedCard['Questions to Ask']">
          <li v-for="(question, index) in selectedCard['Questions to Ask']" :key="index">{{ question }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      cards: [],
      categorizedCards: null,
      selectedCard: null,
      debugInfo: 'Initializing...'
    };
  },
  methods: {
    getCardImage(imageName) {
      return `https://nantarat.xyz/api/card_image/${imageName}`;
    },
    selectCard(card) {
      this.selectedCard = card;
    },
    closeModal() {
      this.selectedCard = null;
    },
    categorizeCards() {
      if (!Array.isArray(this.cards) || this.cards.length === 0) {
        this.debugInfo = 'No cards to categorize';
        return;
      }

      this.debugInfo = `Categorizing ${this.cards.length} cards`;
      const categorized = {
        'Major Arcana': [],
        'Wands': [],
        'Cups': [],
        'Swords': [],
        'Pentacles': []
      };

      this.cards.forEach(card => {
        if (!card) {
          console.warn('Undefined card encountered');
          return;
        }
        if (card.arcana === 'Major Arcana') {
          categorized['Major Arcana'].push(card);
        } else if (card.suit && card.suit !== 'Trump') {
          if (!categorized[card.suit]) {
            categorized[card.suit] = [];
          }
          categorized[card.suit].push(card);
        } else {
          console.warn('Card without proper categorization:', card);
        }
      });

      // Sort Major Arcana cards by their number
      categorized['Major Arcana'].sort((a, b) => {
        const aNum = a.number === undefined ? -1 : parseInt(a.number);
        const bNum = b.number === undefined ? -1 : parseInt(b.number);
        return aNum - bNum;
      });

      this.categorizedCards = categorized;
      this.debugInfo = `Categorized into ${Object.keys(this.categorizedCards).length} categories. Major Arcana: ${categorized['Major Arcana'].length}`;
    },
    async fetchCards() {
      try {
        this.debugInfo = 'Fetching cards...';
        const response = await axios.get('https://nantarat.xyz/api/cards');
        console.log('API Response:', response.data); // Log the entire response

        if (response.data && Array.isArray(response.data.cards)) {
          this.cards = response.data.cards;
          this.debugInfo = `Fetched ${this.cards.length} cards`;
          this.categorizeCards();
        } else if (Array.isArray(response.data)) {
          this.cards = response.data;
          this.debugInfo = `Fetched ${this.cards.length} cards`;
          this.categorizeCards();
        } else {
          throw new Error('Unexpected API response structure');
        }
      } catch (error) {
        console.error('Error fetching cards:', error);
        this.debugInfo = `Error: ${error.message}`;
        this.cards = []; // Initialize to empty array to prevent further errors
      }
    }
  },
  mounted() {
    this.debugInfo = 'Component mounted';
    this.fetchCards();
  }
};
</script>

<style scoped>
.card-gallery {
  padding: 20px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.card {
  cursor: pointer;
  text-align: center;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.card img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: var(--card-background);
  color: var(--text-color);
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.modal-content img {
  max-width: 200px;
  height: auto;
  display: block;
  margin: 0 auto;
}

h1, h2 {
  color: var(--primary-color);
}

ul {
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}
</style>