<template>
    <div class="numura-tarot">
      <h2>Numura-Tarot Synthesis Reading</h2>
      
      <div class="user-input" v-if="!readingGenerated">
        <input v-model="dateOfBirth" type="date" placeholder="Date of Birth">
        <input v-model="fullName" type="text" placeholder="Full Name">
        <div class="spread-selection">
          <button @click="spreadType = 3" :class="{ active: spreadType === 3 }">3 Card Spread</button>
          <button @click="spreadType = 5" :class="{ active: spreadType === 5 }">5 Card Spread</button>
        </div>
        <select v-model="questionCategory">
          <option value="">Select a category</option>
          <option value="career">Work & Career</option>
          <option value="love">Love & Relationships</option>
          <option value="health">Health & Wellness</option>
          <option value="finance">Finance & Wealth</option>
          <option value="personal">Personal Growth</option>
        </select>
        <input v-model="specificQuestion" type="text" placeholder="Specific question (optional)">
        <button @click="generateReading" :disabled="!canGenerate || isLoading">Generate Reading</button>
      </div>
  
      <div v-if="isLoading" class="loading">Generating your personalized Numura-Tarot reading...</div>
  
      <div v-if="readingGenerated" class="reading-result">
        <div class="numerology-profile">
          <h3>Your Numerology Profile</h3>
          <p><strong>Life Path Number:</strong> {{ numerologyProfile.lifePath }}</p>
          <p><strong>Expression Number:</strong> {{ numerologyProfile.expression }}</p>
          <p><strong>Soul Urge Number:</strong> {{ numerologyProfile.soulUrge }}</p>
          <p><strong>Personality Number:</strong> {{ numerologyProfile.personality }}</p>
          <p><strong>Birthday Number:</strong> {{ numerologyProfile.birthday }}</p>
        </div>
  
        <div class="tarot-cards">
          <div v-for="(card, index) in drawnCards" :key="index" class="card">
            <img :src="getCardImage(card.img)" :alt="card.name">
            <h4>{{ card.name }}</h4>
            <p>{{ card.keywords.join(', ') }}</p>
          </div>
        </div>
  
        <div class="interpretation" v-html="formattedInterpretation"></div>
  
        <button @click="translateReading" :disabled="isTranslating">
          {{ isTranslating ? 'Translating...' : 'Translate to Thai' }}
        </button>
        
        <div v-if="thaiTranslation" class="thai-translation">
          <h3>Thai Translation</h3>
          <div v-html="formattedThaiTranslation"></div>
        </div>
  
        <button @click="resetReading">Start New Reading</button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  const LETTER_VALUES = {
    A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, I: 9,
    J: 1, K: 2, L: 3, M: 4, N: 5, O: 6, P: 7, Q: 8, R: 9,
    S: 1, T: 2, U: 3, V: 4, W: 5, X: 6, Y: 7, Z: 8
  };
  
  export default {
    data() {
      return {
        dateOfBirth: '',
        fullName: '',
        spreadType: 3,
        questionCategory: '',
        specificQuestion: '',
        isLoading: false,
        isTranslating: false,
        readingGenerated: false,
        numerologyProfile: {},
        drawnCards: [],
        interpretation: '',
        thaiTranslation: ''
      };
    },
    computed: {
      canGenerate() {
        return this.dateOfBirth && this.fullName && this.questionCategory;
      },
      formattedInterpretation() {
        if (!this.interpretation) return '';
        const sections = this.interpretation.split('\n\n');
        return sections.map(section => {
          if (section.startsWith('###')) {
            const [title, ...content] = section.split('\n');
            return `<h4>${title.replace('###', '').trim()}</h4><p>${content.join('\n')}</p>`;
          } else {
            return `<p>${section}</p>`;
          }
        }).join('');
      },
      formattedThaiTranslation() {
        if (!this.thaiTranslation) return '';
        const sections = this.thaiTranslation.split('\n\n');
        return sections.map(section => {
          if (section.startsWith('###')) {
            const [title, ...content] = section.split('\n');
            return `<h4>${title.replace('###', '').trim()}</h4><p>${content.join('\n')}</p>`;
          } else {
            return `<p>${section}</p>`;
          }
        }).join('');
      }
    },
    methods: {
      async generateReading() {
        console.log("Generate reading started");
        this.isLoading = true;
        try {
          console.log("Calculating numerology profile");
          this.numerologyProfile = this.calculateNumerologyProfile();
          console.log("Numerology profile:", this.numerologyProfile);
  
          console.log("Drawing cards");
          this.drawnCards = await this.drawCards();
          console.log("Drawn cards:", this.drawnCards);
  
          console.log("Getting interpretation");
          this.interpretation = await this.getInterpretation();
          console.log("Interpretation:", this.interpretation);
  
          this.readingGenerated = true;
          console.log("Reading generated successfully");
        } catch (error) {
          console.error('Error generating reading:', error);
        } finally {
          this.isLoading = false;
          console.log("Generate reading finished");
        }
      },
      calculateNumerologyProfile() {
        const birthDate = new Date(this.dateOfBirth);
        const day = birthDate.getDate();
        const month = birthDate.getMonth() + 1;
        const year = birthDate.getFullYear();
  
        const profile = {
          lifePath: this.calculateLifePathNumber(day, month, year),
          expression: this.calculateExpressionNumber(this.fullName),
          soulUrge: this.calculateSoulUrgeNumber(this.fullName),
          personality: this.calculatePersonalityNumber(this.fullName),
          birthday: this.reduceNumber(day)
        };
  
        console.log("Calculated Numerology Profile:", JSON.stringify(profile, null, 2));
        return profile;
      },
      calculateLifePathNumber(day, month, year) {
        console.log(`Calculating Life Path Number for ${day}/${month}/${year}`);
        const sum = this.reduceNumber(day) + this.reduceNumber(month) + this.reduceNumber(year);
        return this.reduceNumber(sum);
      },
      calculateExpressionNumber(name) {
        console.log(`Calculating Expression Number for ${name}`);
        let sum = 0;
        name.toUpperCase().replace(/\s/g, '').split('').forEach(char => {
          const value = LETTER_VALUES[char] || 0;
          sum += value;
          console.log(`Letter: ${char}, Value: ${value}, Sum: ${sum}`);
        });
        return this.reduceNumber(sum);
      },
      calculateSoulUrgeNumber(name) {
        console.log(`Calculating Soul Urge Number for ${name}`);
        const vowels = 'AEIOU';
        let sum = 0;
        name.toUpperCase().replace(/\s/g, '').split('').forEach(char => {
          if (vowels.includes(char)) {
            const value = LETTER_VALUES[char];
            sum += value;
            console.log(`Vowel: ${char}, Value: ${value}, Sum: ${sum}`);
          }
        });
        return this.reduceNumber(sum);
      },
      calculatePersonalityNumber(name) {
        console.log(`Calculating Personality Number for ${name}`);
        const vowels = 'AEIOU';
        let sum = 0;
        name.toUpperCase().replace(/\s/g, '').split('').forEach(char => {
            if (!vowels.includes(char)) {
            const value = LETTER_VALUES[char] || 0;
            sum += value;
            console.log(`Consonant: ${char}, Value: ${value}, Sum: ${sum}`);
            }
        });
        console.log(`Personality Number before reduction: ${sum}`);
        let result = this.reduceNumber(sum);
        // Apply reduction one more time if the result is still greater than 9
        if (result > 9) {
            result = this.reduceNumber(result);
        }
        console.log(`Personality Number after reduction: ${result}`);
        return result;
        },

        reduceNumber(num) {
        console.log(`Reducing: ${num}`);
        if (num <= 9 || num === 11 || num === 22) return num;
        return this.reduceNumber(String(num).split('').reduce((a, b) => Number(a) + Number(b), 0));
        },
      async drawCards() {
        try {
          const response = await axios.get(`https://nantarat.xyz/api/reading/${this.spreadType === 3 ? 'three' : 'single'}`);
          if (this.spreadType === 5) {
            const additionalCards = await axios.get('https://nantarat.xyz/api/reading/single');
            return [...response.data, ...additionalCards.data];
          }
          return response.data;
        } catch (error) {
          console.error("Error drawing cards:", error);
          throw error;
        }
      },
      async getInterpretation() {
        try {
          const response = await axios.post('https://nantarat.xyz/api/reading/numura_tarot', {
            numerologyProfile: this.numerologyProfile,
            drawnCards: this.drawnCards,
            questionCategory: this.questionCategory,
            specificQuestion: this.specificQuestion,
            userName: this.fullName
          });
          
          return response.data.interpretation;
        } catch (error) {
          console.error("Error getting interpretation:", error);
          throw error;
        }
      },
      getCardImage(imageName) {
        return `https://nantarat.xyz/api/card_image/${imageName}`;
      },
      async translateReading() {
        this.isTranslating = true;
        try {
          const response = await axios.post('https://nantarat.xyz/api/translate', {
            text: this.interpretation,
            prompt: `Translate the following Numura-Tarot reading into Thai, maintaining the spiritual and intuitive tone:`
          });
          if (response.data && response.data.translation) {
            this.thaiTranslation = response.data.translation;
          } else {
            throw new Error('Unexpected response structure');
          }
        } catch (error) {
          console.error('Error translating reading:', error);
        } finally {
          this.isTranslating = false;
        }
      },
      resetReading() {
        this.readingGenerated = false;
        this.dateOfBirth = '';
        this.fullName = '';
        this.questionCategory = '';
        this.specificQuestion = '';
        this.numerologyProfile = {};
        this.drawnCards = [];
        this.interpretation = '';
        this.thaiTranslation = '';
      }
    }
  };
  </script>
  
  <style scoped>
  .numura-tarot {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .user-input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .spread-selection {
    display: flex;
    gap: 10px;
  }
  
  .spread-selection button {
    flex: 1;
  }
  
  .active {
    background-color: #bb86fc;
    color: white;
  }
  
  .tarot-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .card {
    text-align: center;
    width: 150px;
  }
  
  .card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  button {
    background-color: #6200ee;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .interpretation, .thai-translation {
    margin-top: 20px;
    text-align: left;
  }
  
  .loading {
    text-align: center;
    margin-top: 20px;
  }
  </style>