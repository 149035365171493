=<template>
  <div class="card-of-the-day">
    <h2>Card of the Day</h2>
    <div v-if="isLoading" class="loading">Loading your daily guidance...</div>
    <div v-if="error" class="error">{{ error }}</div>
    <div v-if="card && interpretation" class="reading-result">
      <h3>{{ card.name }}</h3>
      <div class="card-container">
        <img :src="getCardImage(card.img)" :alt="card.name" />
      </div>
      <p class="keywords"><strong>Keywords:</strong> {{ card.keywords.join(', ') }}</p>
      <div class="interpretation">
        <h3>Aya's Interpretation</h3>
        <div v-html="formattedInterpretation"></div>
      </div>
      <button @click="translateReading" :disabled="isTranslating">
        {{ isTranslating ? 'Translating...' : 'Translate to Thai' }}
      </button>
      <div v-if="thaiTranslation" class="thai-translation">
        <h3>Thai Translation</h3>
        <div v-html="formattedThaiTranslation"></div>
      </div>
    </div>
    <button @click="fetchCardOfTheDay" :disabled="isLoading">Get New Card of the Day</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      isLoading: true,
      isTranslating: false,
      error: null,
      card: null,
      interpretation: '',
      thaiTranslation: ''
    };
  },
  computed: {
    formattedInterpretation() {
      if (!this.interpretation) return '';
      
      const sections = this.interpretation.split('\n\n');
      return sections.map(section => {
        if (section.startsWith('###')) {
          const [title, ...content] = section.split('\n');
          return `<h4>${title.replace('###', '').trim()}</h4><p>${content.join('\n')}</p>`;
        } else {
          return `<p>${section}</p>`;
        }
      }).join('');
    },
    formattedThaiTranslation() {
      if (!this.thaiTranslation) return '';
      
      const sections = this.thaiTranslation.split('\n\n');
      return sections.map(section => {
        if (section.startsWith('###')) {
          const [title, ...content] = section.split('\n');
          return `<h4>${title.replace('###', '').trim()}</h4><p>${content.join('\n')}</p>`;
        } else {
          return `<p>${section}</p>`;
        }
      }).join('');
    }
  },
  methods: {
    async fetchCardOfTheDay() {
      this.isLoading = true;
      this.error = null;
      try {
        const response = await axios.get('https://nantarat.xyz/api/reading/card_of_the_day');
        if (response.data && response.data.card && response.data.interpretation) {
          this.card = response.data.card;
          this.interpretation = response.data.interpretation;
        } else {
          throw new Error('Unexpected response structure');
        }
      } catch (error) {
        console.error('Error fetching card of the day:', error);
        this.error = "Unable to fetch your Card of the Day. Please try again later.";
      } finally {
        this.isLoading = false;
      }
    },
    getCardImage(imageName) {
      return `https://nantarat.xyz/api/card_image/${imageName}`;
    },
    async translateReading() {
      this.isTranslating = true;
      this.error = null;
      try {
        const response = await axios.post('https://nantarat.xyz/api/translate', {
          text: this.interpretation,
          prompt: `You are a skilled Thai translator with expertise in tarot readings and spiritual content. Your task is to translate the following English tarot reading into Thai. Please follow these guidelines:

1. Maintain the warm, friendly tone of the original text.
2. Ensure the translation is culturally appropriate for Thai readers.
3. Use natural, flowing Thai language that sounds conversational yet respectful.
4. Preserve the structure of the original reading (greeting, card introduction, daily guidance, etc.).
5. Adapt any idioms or expressions to Thai equivalents that convey the same meaning.
6. Pay special attention to the smoothness and readability of the translation, ensuring that it flows naturally and does not sound awkward or overly literal.
7. Double-check that astrological and tarot terms are correctly translated using standard Thai terminology.
8. After translating, review the entire text to ensure it reads smoothly and coherently in Thai, making adjustments where necessary for clarity and emotional impact.
9. If any concepts are difficult to translate directly, provide a culturally relevant explanation in parentheses.

Here's the English text to translate:

${this.interpretation}

Please provide the Thai translation after checking and make sure it's accurate and reads well in modern Thai.`
        });
        if (response.data && response.data.translation) {
          this.thaiTranslation = response.data.translation;
        } else {
          throw new Error('Unexpected response structure');
        }
      } catch (error) {
        console.error('Error translating reading:', error);
        this.error = "Failed to translate the reading. Please try again later.";
      } finally {
        this.isTranslating = false;
      }
    }
  },
  mounted() {
    this.fetchCardOfTheDay();
  }
};
</script>

<style scoped>
.card-of-the-day {
  width: 100%;
  max-width: 1200px; /* Adjust this value as needed */
  margin: 0 auto;
  padding: 20px 40px; /* Adjust these values for desired padding */
  box-sizing: border-box;
}

.card-container {
  text-align: center;
  margin-bottom: 20px;
}

.card-container img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.keywords {
  text-align: center;
  margin-bottom: 20px;
}

.interpretation {
  text-align: left;
  width: 100%;
}

.interpretation h4 {
  color: #bb86fc; /* Purple color for topic headers */
  margin-top: 20px;
  margin-bottom: 10px;
}

.thai-translation {
  margin-top: 20px;
  padding: 15px;
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-color);
}

button {
  margin-top: 15px;
  padding: 10px 15px;
  background-color: var(--primary-color);
  color: var(--button-text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--secondary-color);
}

button:disabled {
  background-color: var(--disabled-color);
  cursor: not-allowed;
}
</style>