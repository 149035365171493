<template>
  <div id="app">
    <nav class="navbar">
      <div class="navbar-brand">Tarot App</div>
      <ThemeToggle />
      <button class="navbar-toggle" @click="toggleMenu" aria-label="Toggle menu">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ul class="navbar-menu" :class="{ 'is-active': isMenuActive }">
        <li><a href="#" @click.prevent="setView('gallery')">Card Gallery</a></li>
        <li><a href="#" @click.prevent="setView('slider')">Slider</a></li>
        <li><a href="#" @click.prevent="setView('single')">Single Card Reading</a></li>
        <li><a href="#" @click.prevent="setView('three')">Three Card Reading</a></li>
        <li><a href="#" @click.prevent="setView('aiThree')">AI Three Card Reading</a></li>
        <li><a href="#" @click.prevent="setView('cardOfTheDay')">Card of the Day</a></li>
        <li><a href="#" @click.prevent="setView('tarotSpread')">Tarot Spread</a></li>
        <li><a href="#" @click.prevent="setView('numuraTarot')">Numura-Tarot</a></li>
      </ul>
    </nav>
    <component :is="currentComponent"></component>
  </div>
</template>

<script>
import ThemeToggle from './components/ThemeToggle.vue';
import CardSlider from './components/CardSlider.vue';
import CardGallery from './components/CardGallery.vue';
import SingleCardReading from './components/SingleCardReading.vue';
import ThreeCardReading from './components/ThreeCardReading.vue';
import AIThreeCardReading from './components/AIThreeCardReading.vue';
import CardOfTheDay from './components/CardOfTheDay.vue';
import TarotSpread from './components/TarotSpread.vue';
import NumuraTarot from './components/NumuraTarot.vue';

export default {
  name: 'App',
  data() {
    return {
      view: 'gallery',
      isMenuActive: false
    };
  },
  computed: {
    currentComponent() {
      console.log('Current view:', this.view);
      switch (this.view) {
        case 'single': return SingleCardReading;
        case 'three': return ThreeCardReading;
        case 'aiThree': return AIThreeCardReading;
        case 'cardOfTheDay': return CardOfTheDay;
        case 'tarotSpread': return TarotSpread;
        case 'slider': return CardSlider;
        case 'numuraTarot': return NumuraTarot;
        default: return CardGallery;
      }
    }
  },
  methods: {
    setView(newView) {
      console.log('Setting view to:', newView);
      this.view = newView;
      this.isMenuActive = false; // Close menu after selection on mobile
    },
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    }
  },
  components: {
    ThemeToggle,
    CardSlider,
    CardGallery,
    SingleCardReading,
    ThreeCardReading,
    AIThreeCardReading,
    CardOfTheDay,
    TarotSpread,
    NumuraTarot
  }
};
</script>

<style lang="scss">
.navbar {
  background-color: var(--primary-color);
  box-shadow: var(--card-shadow);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  &-brand {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;

    span {
      display: block;
      width: 25px;
      height: 3px;
      background-color: white;
      margin: 5px 0;
    }
  }

  &-menu {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-left: 1rem;

      a {
        color: white;
        text-decoration: none;
        padding: 0.5rem 1rem;
        border-radius: 4px;
        transition: background-color var(--transition-speed);

        &:hover {
          background-color: var(--secondary-color);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    &-toggle {
      display: block;
    }

    &-menu {
      display: none;
      width: 100%;
      flex-direction: column;
      align-items: center;

      &.is-active {
        display: flex;
      }

      li {
        margin: 0.5rem 0;
      }
    }
  }
}
</style>